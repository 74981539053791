<template>
  <div class="options cias pd-20">
    <a-page-header
      class="mb-10"
      style="padding: 0"
      sub-title=""
      @back="() => $router.go(-1)"
    >
      <div slot="title">
        <h1>{{ company.id }} - Editar Empresa</h1>
      </div>
      <template slot="extra"> </template>
    </a-page-header>

    <a-form :form="form" @submit="handleSubmit">
      <a-row :gutter="[20, 20]">
        <a-col class="box box-contractor" :span="8">
          <a-row :gutter="20" class="actions mt-20" style="min-height: 570px">
            <h2 class="title mb-20">DADOS DA EMPRESA</h2>
            <a-col :span="24">
              <a-form-item class="travel-input-outer">
                <label
                  for
                  :class="form.getFieldValue(`company_name`) ? 'filled' : ''"
                >
                  Razão social
                </label>

                <a-input
                  class="travel-input"
                  placeholder="Razão social"
                  v-decorator="[
                    `company_name`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                >
                  <a-icon slot="prefix" type="field-svg" />
                </a-input>
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item class="travel-input-outer">
                <label
                  for
                  :class="form.getFieldValue(`trading_name`) ? 'filled' : ''"
                >
                  Nome Fantasia
                </label>

                <a-input
                  class="travel-input"
                  placeholder="Nome Fantasia"
                  v-decorator="[
                    `trading_name`,
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                >
                  <a-icon slot="prefix" type="field-svg" />
                </a-input>
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item class="travel-input-outer">
                <label for :class="form.getFieldValue(`cnpj`) ? 'filled' : ''">
                  CNPJ
                </label>

                <a-input
                  class="travel-input"
                  placeholder="CNPJ"
                  v-mask="'##.###.###/####-##'"
                  v-decorator="[`cnpj`]"
                >
                  <a-icon slot="prefix" type="field-svg" />
                </a-input>
              </a-form-item>
            </a-col>

            <a-col :span="24">
              <a-form-item class="travel-input-outer">
                <label for :class="'filled'"> Endereço completo </label>

                <a-input
                  class="travel-input"
                  placeholder="Escreva"
                  v-decorator="[
                    `address`,
                    {
                      rules: [
                        {
                          required: false,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                >
                  <a-icon slot="prefix" type="field-svg" />
                </a-input>
              </a-form-item>
            </a-col>
          </a-row>
        </a-col>

        <a-col class="box box-contractor" :span="16">
          <a-row :gutter="20" class="actions mt-20" style="min-height: 570px">
            <a-row class="title-box mb-20" type="flex" justify="space-between">
              <a-col>
                <h3>FILIAIS</h3>
              </a-col>
              <a-col>
                <a-button
                  type="link"
                  size="small"
                  ghost
                  @click="showCreateCompanyBranch = true"
                >
                  <a-icon type="plus" /> ADICIONAR FILIAL
                </a-button>

                <a-modal
                  v-model="showCreateCompanyBranch"
                  title="NOVA FILIAL"
                  :footer="false"
                  width="340px"
                  @cancel="cancelCompanyBranchCreation"
                >
                  <a-input
                    v-model="companyBranch.name"
                    placeholder="Nome da Filial"
                  />

                  <a-popconfirm
                    ok-text="Sim"
                    cancel-text="Cancelar"
                    placement="bottom"
                    @confirm="createNewCompanyBranch"
                  >
                    <template slot="title">
                      <div style="font-size: 13px">
                        Após a criação da filial, ela
                        <br />
                        não poderá ser apagada. <br />
                        Deseja continuar?
                      </div>
                    </template>
                    <a-button
                      class="mt-20"
                      type="primary"
                      style="width: 100%; font-weight: 500"
                      :loading="companyBranch.loading"
                    >
                      CRIAR FILIAL
                    </a-button>
                  </a-popconfirm>
                </a-modal>
              </a-col>
            </a-row>
            <div class="travel-table last-itens" style="padding: 5px 10px">
              <HayaTable
                :data-source="companyBranches.list"
                :loading="companyBranches.loading"
                :pagination="false"
                :paginator="companyBranches.pagination"
                :columns="[
                  {
                    title: 'ID ',
                    dataIndex: 'id',
                    key: 'id',
                    class: 'id',
                  },
                  {
                    title: 'Nome da Filial',
                    dataIndex: 'name',
                    key: 'name',
                    class: 'name',
                    width: 200,
                  },
                  {
                    title: 'Logo',

                    scopedSlots: {
                      customRender: 'logo',
                    },
                  },
                  {
                    title: 'Mostrar no contrato',

                    scopedSlots: {
                      customRender: 'show_in_contract',
                    },
                  },
                  {
                    title: 'Status',
                    dataIndex: 'status',
                    key: 'status',
                    class: 'status',
                  },
                  {
                    title: 'Data/Hora',
                    scopedSlots: {
                      customRender: 'created',
                    },
                  },
                  {
                    title: 'Ação',
                    align: 'right',
                    width: 200,

                    scopedSlots: { customRender: 'action' },
                  },
                ]"
              >
                <template slot="created" slot-scope="record">
                  {{ record.created }}
                </template>

                <template slot="logo" slot-scope="record">
                  <div v-if="editInlineField == record.id">
                    <span v-if="!theCompanyBranch.logo">
                      <a-upload
                        name="file"
                        :multiple="false"
                        :action="`https://api.viajar.tur.br/v1/media/upload.php?folder=public/company-branch/${record.id}`"
                        :remove="removeUploadedFile"
                        :headers="{
                          authorization: `Bearer ${$store.state.token}`,
                        }"
                        @change="uploadedFile"
                        v-model="theCompanyBranch.logo"
                      >
                        <a-button type="dashed" class="mt-5" shape="circle">
                          <a-icon type="file" />
                        </a-button>
                      </a-upload>
                    </span>
                    <span v-if="theCompanyBranch.logo">
                      <img :src="theCompanyBranch.logo" width="40" alt="img" />
                      <a-icon
                        @click="theCompanyBranch.logo = ''"
                        class="red ml-10"
                        type="close-circle"
                      />
                    </span>
                  </div>

                  <div v-if="editInlineField != record.id">
                    <div v-if="record.logo">
                      <img :src="record.logo" width="40" alt="img" />
                    </div>
                  </div>
                </template>

                <template slot="show_in_contract" slot-scope="record">
                  <div v-if="record.show_in_contract == 1">
                    <a-icon class="cgreen" type="check" />
                  </div>
                </template>

                <template slot="action" slot-scope="record">
                  <div class="actions-outer" style="position: relative">
                    <a-tooltip placement="top" title="Editar">
                      <a class="edit ml-15" @click="edit(record.raw)">
                        <a-icon type="edit-svg" />
                      </a>
                    </a-tooltip>
                  </div>
                </template>
              </HayaTable>
            </div>

            <div style="float: left; width: 100%; clear: both"></div>
          </a-row>
        </a-col>

        <a-col class="a-rightt" :span="24">
          <a-button
            size="large"
            type="primary"
            html-type="submit"
            :loading="loading"
            style="float: right"
          >
            SALVAR ALTERAÇÕES
          </a-button>
        </a-col>
      </a-row>
    </a-form>

    <a-drawer
      placement="right"
      width="640px"
      :closable="true"
      :visible="openEditCompanyBranch"
      @close="openEditCompanyBranch = false"
    >
      <template slot="title">
        <a-icon type="edit" class="cprimary mr-5" /> EDITAR FILIAL
      </template>

      <EditCompanyBranchData
        v-if="openEditCompanyBranch"
        :theCompanyBranch="theCompanyBranch"
        @listCompanyBranches="closeDrawerAndGetCompanyBranches"
      />
    </a-drawer>
  </div>
</template>

<script>
import HayaTable from "@/components/general/HayaTable.vue";
import companyBranchMixins from "@/mixins/company-branches/companyBranchMixins.js";
import EditCompanyBranchData from "@/components/companies/forms/EditCompanyBranchData.vue";

export default {
  name: "EditCompany",
  components: { HayaTable, EditCompanyBranchData },
  mixins: [companyBranchMixins],
  data() {
    return {
      form: this.$form.createForm(this),
      company: {},
      openEditCompanyBranch: false,
      showCreateCompanyBranch: false,
      loading: false,
      editInlineField: "",
      theCompanyBranch: {},
      loadingCompanyBranchUpdate: false,
      companyBranch: {
        name: undefined,
        loading: false,
      },
    };
  },
  beforeMount() {
    this.getCompany();
  },
  methods: {
    closeDrawerAndGetCompanyBranches() {

      this.companyBranches.pagination.perPage = 100
      this.getCompanyBranches();
      this.openEditCompanyBranch = false;
    },
    edit(companyBranch) {
      this.openEditCompanyBranch = true;
      this.theCompanyBranch = companyBranch;
    },
    editInline(companyBranch) {
      this.editInlineField = companyBranch.id;
      this.theCompanyBranch = companyBranch.raw;
    },
    saveInlineEdit() {
      this.loadingCompanyBranchUpdate = true;
      this.$http
        .post("/company-branch/update", this.theCompanyBranch)
        .then(({ data }) => {
          data;
          this.$message.success("Atualizado com sucesso!");
          this.$http
            .post("/log/create", {
              user_id: this.$store.state.userData.id,
              module_id: this.theCompanyBranch.id,
              module: "company-branch",
              action: "update-logo",
              description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} atualizou a logo da filial ID ${this.theCompanyBranch.id}.`,
            })
            .then(({ data }) => {
              data;
            });

          this.editInlineField = "";
          this.theCompanyBranch = {};
          this.loadingCompanyBranchUpdate = false;
          this.getCompanyBranches();
        })
        .catch(({ res }) => {
          res;
          this.$message.error("Não foi possível atualizar.");
          this.loadingCompanyBranchUpdate = false;
          this.editInlineField = "";
          this.theCompanyBranch = {};
        });
    },
    cancelInlineEdit() {
      this.editInlineField = "";
      this.theCompanyBranch = {};
    },
    uploadedFile(response) {
      console.log("uploadedFile", response);
      if (response.file.response.url != undefined) {
        this.theCompanyBranch.logo = response.file.response.url;
      }
    },
    createNewCompanyBranch() {
      if (this.companyBranch.name) {
        this.companyBranch.loading = true;
        this.companyBranch.miles_range = "[]";
        this.companyBranch.user_id = this.$store.state.userData.id;
        this.companyBranch.company_id = this.company.id;
        this.companyBranch.status = "Ativo";

        this.$http
          .post("/company-branch/create", this.companyBranch)
          .then(({ data }) => {
            this.$message.success(data.message);
            this.companyBranch.loading = false;
            this.showCreateCompanyBranch = false;
            this.companyBranch = {
              name: undefined,
              loading: false,
            };
            this.getCompany();
          })
          .catch(({ response }) => {
            this.$message.error(response.data.message);
            this.companyBranch.loading = false;
          });
      } else {
        this.$message.warning("Escreva o nome da filial.");
      }
    },
    cancelCompanyBranchCreation() {
      this.companyBranch = {
        name: undefined,
        loading: false,
      };
    },
    getCompany() {
      this.$http
        .post(`/company/details?id=${this.$route.params.id}`)
        .then(({ data }) => {
          this.company = data;

          document.title = `${data.id} - ${data.company_name} - HAYA`;

          this.form.setFieldsValue({
            [`company_name`]: data.company_name,
            [`trading_name`]: data.trading_name,
            [`cnpj`]: data.cnpj,
            [`address`]: data.address,
          });

          this.companyBranches.pagination.perPage = 100
          this.companyBranches.filters.companyId = data.id;
          this.getCompanyBranches();

          this.loadingCreateCompany = false;
          this.$emit("listCompanies", true);
        })
        .catch(({ response }) => {
          response;
          this.loadingCreateCompany = false;
        });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        values.id = this.company.id;
        values.user_id = this.company.user.id;
        values.modified_by = {
          name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
          id: this.$store.state.userData.id,
        };

        console.log(err, values);

        if (!err) {
          this.loading = true;
          this.$http
            .post("/company/update", values)
            .then(({ data }) => {
              data;
              this.$message.success("Atualizado com sucesso!");
              this.loading = false;
            })
            .catch(({ res }) => {
              res;
              this.$message.error("Não foi possível atualizar.");
              this.loading = false;
            });
        } else {
          this.$message.warning("Alguns campos ainda não foram preenchidos.");
        }
      });
    },
  },
};
</script>
